import { Component, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { PaginationRange } from 'tiime-components';

import { CallbackComponentBase, MagicLinksService, UsersService } from '@core';
import { AppOpenIdentified } from '@core/amplitude';
import { LOCAL_STORAGE } from '@core/tokens';
import { CompaniesService } from '@services/companies.service';

@Component({
  templateUrl: './connect-as-callback.component.html',
  styleUrls: ['./connect-as-callback.component.scss'],
})
export class ConnectAsCallbackComponent extends CallbackComponentBase {
  constructor(
    router: Router,
    store: Store,
    usersService: UsersService,
    private readonly companiesService: CompaniesService,
    private readonly route: ActivatedRoute,
    private readonly magicLinksService: MagicLinksService,
    @Inject(LOCAL_STORAGE) private readonly storage: Storage,
    private readonly trackingService: TrackingService,
  ) {
    super(router, store, usersService);
  }

  initAuthentication(): void {
    this.storage.removeItem('access_token');
    this.route.queryParamMap
      .pipe(
        take(1),
        tap(queryParamsMap => {
          const accessToken = queryParamsMap.get('access_token');

          if (accessToken === null) {
            throw new Error('The query param access_token is required');
          }

          this.storeAccessToken(accessToken);
          this.magicLinksService.setIsLoggedInViaMagicLink(
            queryParamsMap.get('magic_link'),
          );
        }),
        tap(queryParamsMap => {
          this.trackingService.dispatch(new AppOpenIdentified());
          this.handleRedirection(queryParamsMap);
        }),
        catchError(() => {
          this.navigateToLogin();
          return EMPTY;
        }),
      )
      .subscribe();
  }

  private storeAccessToken(accessToken: string): void {
    this.storage.setItem('access_token', accessToken);
  }

  private handleRedirection(queryParams: ParamMap): void {
    this.getSelectedCompanyId()
      .pipe(
        switchMap(companyId =>
          this.handleRedirectionFromQueryParams(companyId, queryParams, [
            'companies',
            companyId,
            'account',
          ]),
        ),
      )
      .subscribe();
  }

  private navigateToLogin(): void {
    this.storage.removeItem('access_token');
    void this.router.navigate(['/']);
  }

  private getSelectedCompanyId(): Observable<number> {
    return this.getCurrentUser().pipe(
      switchMap(({ activeCompanyId }) =>
        activeCompanyId
          ? of(activeCompanyId)
          : this.companiesService
              .getAll({ range: new PaginationRange(0, 0) })
              .pipe(map(companies => companies.data[0].id)),
      ),
    );
  }
}

import { UserPermissionNameEnum } from '@enums/users';

export type RestrictedRouteType = {
  routeToReplace: string;
  permissionNeeded: UserPermissionNameEnum;
  replacementRoute: string;
  method: string;
  params?: {
    name: string; // the string to replace in the replacementRoute
    position: number; // the position of the parameter in the routeToReplace -(starts at 0, count segments between slashes)
  }[];
};

export const restrictedRoutes: RestrictedRouteType[] = [
  {
    routeToReplace: 'api/v1/companies/{companyId}/dashboard_blocks',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute: 'api/v1/companies/{companyId}/dashboard_blocks_invoice',
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/dashboard_blocks',
    method: 'POST',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute: 'api/v1/companies/{companyId}/dashboard_blocks_invoice',
  },
  {
    routeToReplace:
      'api/v1/companies/{companyId}/dashboard_blocks/{dashboardId}',
    method: 'DELETE',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/dashboard_blocks_invoice/{dashboardId}',
    params: [
      {
        name: 'dashboardId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace:
      'api/v1/companies/{companyId}/dashboard_blocks/{dashboardId}',
    method: 'PATCH',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/dashboard_blocks_invoice/{dashboardId}',
    params: [
      {
        name: 'dashboardId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute: 'api/v1/companies/{companyId}/users/me/documents',
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents/{documentId}',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/documents/{documentId}',
    params: [
      {
        name: 'documentId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents/{documentId}',
    method: 'PATCH',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/documents/{documentId}',
    params: [
      {
        name: 'documentId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents/{documentId}',
    method: 'DELETE',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/documents/{documentId}',
    params: [
      {
        name: 'documentId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace:
      'api/v1/companies/{companyId}/documents/{documentId}/preview',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/documents/{documentId}/preview',
    params: [
      {
        name: 'documentId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents/{documentId}/file',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/documents/{documentId}/file',
    params: [
      {
        name: 'documentId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace:
      'api/v1/companies/{companyId}/expense_reports/{expenseReportId}',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/expense_reports/{expenseReportId}',
    params: [
      {
        name: 'expenseReportId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/bank_transactions',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute: 'api/v1/companies/{companyId}/users/me/bank_transactions',
  },
  {
    routeToReplace:
      'api/v1/companies/{companyId}/bank_transactions/{bankTransactionId}',
    method: 'GET',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/bank_transactions/{bankTransactionId}',
    params: [
      {
        name: 'bankTransactionId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace:
      'api/v1/companies/{companyId}/bank_transactions/{bankTransactionId}',
    method: 'PATCH',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/bank_transactions/{bankTransactionId}',
    params: [
      {
        name: 'bankTransactionId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace:
      'api/v1/companies/{companyId}/bank_transactions/{bankTransactionId}/tags',
    method: 'PUT',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/bank_transactions/{bankTransactionId}/tags',
    params: [
      {
        name: 'bankTransactionId',
        position: 5,
      },
    ],
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents/tags',
    method: 'PUT',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute: 'api/v1/companies/{companyId}/users/me/documents/tags',
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents/label',
    method: 'PUT',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute: 'api/v1/companies/{companyId}/users/me/documents/label',
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents/category',
    method: 'PUT',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute:
      'api/v1/companies/{companyId}/users/me/documents/category',
  },
  {
    routeToReplace: 'api/v1/companies/{companyId}/documents',
    method: 'DELETE',
    permissionNeeded: UserPermissionNameEnum.ACCOUNTS_USER_FULL,
    replacementRoute: 'api/v1/companies/{companyId}/users/me/documents',
  },
];

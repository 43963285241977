import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';

export class LegalValidationForm extends FormGroup {
  get tos(): FormControl {
    return this.get('tos') as FormControl;
  }
  get tosErrorMessage(): string {
    return this.tos.touched && this.tos.hasError('required')
      ? "Les Conditions Générales d'Utilisations n'ont pas été acceptées"
      : null;
  }
  get gdpr(): FormControl {
    return this.get('gdpr') as FormControl;
  }
  get gdprErrorMessage(): string {
    return this.gdpr.touched && this.gdpr.hasError('required')
      ? "La Politique de données personnelles n'a pas été acceptée"
      : null;
  }

  constructor(
    controls?: { [key: string]: AbstractControl },
    validatorOrOpts?: { validators: ValidatorFn },
  ) {
    super(
      {
        ...controls,
        tos: new FormControl(null, Validators.requiredTrue),
        gdpr: new FormControl(null, Validators.requiredTrue),
      },
      validatorOrOpts,
    );
  }
}

type JwtOptions = { tokenGetter: () => string; allowedDomains: string[] };
type JwtOptionsFactory = {
  addToAllowedDomains: (domains: string[]) => void;
  options: () => JwtOptions;
};

export function jwtOptionsFactory(options: JwtOptions): JwtOptionsFactory {
  const whitelist = options.allowedDomains || [];

  function addToAllowedDomains(domain: string[]): void {
    whitelist.push(...domain);
  }

  return {
    addToAllowedDomains,
    options: (): JwtOptions => ({
      ...options,
      allowedDomains: whitelist,
    }),
  };
}

export const jwtOptions = jwtOptionsFactory({
  tokenGetter: () => localStorage.getItem('access_token'),
  allowedDomains: [],
});

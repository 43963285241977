import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TiimeButtonModule, TiimeInputContainerModule } from 'tiime-components';

import { PasswordToggleModule } from '@core/directives/password-toggle/password-toggle.module';
import { TiimeLayoutComponent } from '@shared';

import { MagicLinkDialogModule } from './magic-link-dialog/magic-link-dialog.module';
import { SigninComponent } from './signin.component';

@NgModule({
  declarations: [SigninComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MagicLinkDialogModule,
    TiimeInputContainerModule,
    TiimeButtonModule,
    PasswordToggleModule,
    TiimeLayoutComponent,
  ],
  exports: [SigninComponent],
})
export class SigninModule {}

/****
 Outsourcing module using custom HTTP_INTERCEPTORS to force them being
 executed before the JwtModule's one.

 The execution order is maintained by the import order of AuthModule and ApiModule in CoreModule.
 JwtModule should be the last one executed as its goal is only to set Authorization header on the final request URL
 ****/

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import {
  ApiInterceptor,
  BlobErrorInterceptor,
  BudgetInsightInterceptor,
} from '@interceptors';

import { AclInterceptor } from './interceptors/acl.interceptor';

@NgModule({
  imports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AclInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BudgetInsightInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class ApiModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ApiModule,
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import it in the CoreModule only',
      );
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TiimeButtonModule, TiimeDialogModule } from 'tiime-components';

import { MagicLinkDialogComponent } from './magic-link-dialog.component';

@NgModule({
  declarations: [MagicLinkDialogComponent],
  imports: [CommonModule, TiimeDialogModule, TiimeButtonModule],
  exports: [MagicLinkDialogComponent],
})
export class MagicLinkDialogModule {}

import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'tiime-components';

const LOADING_CONTEXT_TOKEN = new HttpContextToken<string | undefined>(
  () => undefined,
);

export function loadingContext(requestKey: string): HttpContext {
  return new HttpContext().set(LOADING_CONTEXT_TOKEN, requestKey);
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly loadingService: LoadingService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const requestHandler = next.handle(request);
    const requestKey = request.context.get(LOADING_CONTEXT_TOKEN);

    if (requestKey) {
      this.loadingService.start(requestKey);
    }

    return requestKey ? this.wrap(requestHandler, requestKey) : requestHandler;
  }

  private wrap(
    requestHandler: Observable<HttpEvent<unknown>>,
    requestKey: string,
  ): Observable<HttpEvent<unknown>> {
    return requestHandler.pipe(
      finalize(() => this.loadingService.end(requestKey)),
    );
  }
}

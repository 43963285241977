<div class="create-password-form-container">
  <app-tiime-layout>
    <form
      class="auth-form"
      [formGroup]="legalValidationForm"
      (ngSubmit)="submitLegalValidationForm()"
    >
      <div class="auth-form-header">
        <div class="auth-form-header-illustration"></div>
        <div class="auth-form-header-separator"></div>
        <span class="auth-form-header-title" data-cy="legal-validation-title">
          {{ legalInformations | legalValidationTitle: (currentUser$ | async) }}
        </span>
        <span class="auth-form-header-subtitle">
          {{ legalInformations | legalValidationDescription }}
        </span>
      </div>
      <div class="auth-form-content">
        <div class="input-container legal-input-container --bold">
          <div class="row">
            <mat-checkbox color="primary" formControlName="tos" data-cy="tos-checkbox">
              J'ai lu et j'accepte les
              <a (click)="openLegalDocument($event, LEGAL_DOCUMENT.TOS)">
                Conditions Générales d'Utilisation
              </a>
              de Tiime
            </mat-checkbox>
          </div>
          <label
            *ngIf="legalValidationForm.tosErrorMessage"
            class="input-error-label"
            data-cy="tos-error-message"
          >
            {{ legalValidationForm.tosErrorMessage }}
          </label>
        </div>
        <div class="input-container legal-input-container --bold">
          <div class="row">
            <mat-checkbox color="primary" formControlName="gdpr" data-cy="gdpr-checkbox">
              J'ai lu et j'accepte la
              <a (click)="openLegalDocument($event, LEGAL_DOCUMENT.GDPR)">
                Politique de données personnelles
              </a>
              de Tiime
            </mat-checkbox>
          </div>
          <label
            *ngIf="legalValidationForm.gdprErrorMessage"
            class="input-error-label"
            data-cy="gdpr-error-message"
          >
            {{ legalValidationForm.gdprErrorMessage }}
          </label>
        </div>
      </div>
      <div class="auth-form-actions">
        <button
          type="submit"
          tiime-button
          action
          accent
          data-cy="submit-button"
        >
          Valider
        </button>
      </div>
    </form>
  </app-tiime-layout>
</div>

import { registerLocaleData } from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import moment from 'moment';

import { MOMENT_RELATIVE_TIME_LABEL } from '@constants';
import { CoreModule } from '@core/core.module';
import { AppInjector } from '@core/utils/app-injector';
import { RuntimeEnvironment } from '@environments';
import { AppConfigService } from '@services';
import { IconsModule } from '@shared';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeFr, localeFrExtra);
moment.updateLocale('fr', {
  relativeTime: MOMENT_RELATIVE_TIME_LABEL,
});

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, AppRoutingModule, IconsModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    MatIconRegistry,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector) => {
        return (): void => AppInjector.setInjector(injector);
      },
      deps: [Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService) => {
        return (): Promise<void | RuntimeEnvironment> => {
          return appConfig.loadAppConfig();
        };
      },
      deps: [AppConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

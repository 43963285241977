import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TiimeButtonModule, TiimeCheckboxModule } from 'tiime-components';

import { TiimeLayoutComponent } from '@shared';

import { LegalValidationComponent } from './legal-validation.component';
import { LegalValidationDescriptionPipe } from './pipes/legal-validation-description.pipe';
import { LegalValidationTitlePipe } from './pipes/legal-validation-title.pipe';

@NgModule({
  declarations: [
    LegalValidationComponent,
    LegalValidationTitlePipe,
    LegalValidationDescriptionPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TiimeCheckboxModule,
    TiimeButtonModule,
    TiimeLayoutComponent,
  ],
  exports: [LegalValidationComponent],
})
export class LegalValidationModule {}

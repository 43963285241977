import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, switchMap, take } from 'rxjs';

import { restrictedRoutes, RestrictedRouteType } from '@core/constants/acl';
import { AclService } from '@core/services/acl.service';

@UntilDestroy()
@Injectable()
export class AclInterceptor implements HttpInterceptor {
  constructor(private readonly aclService: AclService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith('api/')) {
      return next.handle(request);
    }
    return this.aclService.getCurrentUserPermissions().pipe(
      take(1),
      map(user => {
        if (!user) {
          return request;
        }
        const restrictedRoute = restrictedRoutes.find(restrictedRoute =>
          this.routesAreEquals(request, restrictedRoute),
        );
        if (!restrictedRoute) {
          return request;
        }

        const shouldReplaceRoute = user.permissionAttributes.some(
          permission =>
            permission.fullName === restrictedRoute.permissionNeeded &&
            request.method === restrictedRoute.method &&
            permission.permit === false,
        );

        if (shouldReplaceRoute) {
          return request.clone({
            url: this.replaceParameters(
              request,
              restrictedRoute.replacementRoute,
              restrictedRoute.params,
            ),
          });
        }
        return request;
      }),
      switchMap(request => next.handle(request)),
      untilDestroyed(this),
    );
  }

  private routesAreEquals(
    request: HttpRequest<unknown>,
    restrictedRoute: RestrictedRouteType,
  ): boolean {
    if (restrictedRoute.method !== request.method) {
      return false;
    }
    if (restrictedRoute.routeToReplace === request.url) {
      return true;
    }

    return (
      request.url ===
      this.replaceParameters(
        request,
        restrictedRoute.routeToReplace,
        restrictedRoute.params,
      )
    );
  }

  private replaceParameters(
    request: HttpRequest<unknown>,
    route: string,
    params?: { name: string; position: number }[],
  ): string {
    let parameterizedUrl = route.slice();
    if (params) {
      params.forEach(param => {
        // get the value of the parameter in the initial route
        const paramValue = request.url.split('/')[param.position];
        // get the position of the parameter in the new route
        const paramIndex = route
          .split('/')
          .findIndex(segment => segment === `{${param.name}}`);
        // replace the placeholder parameter with the value from the initial route
        const segments = route.split('/');
        segments[paramIndex] = paramValue;
        parameterizedUrl = segments.join('/');
      });
    }

    return parameterizedUrl;
  }
}

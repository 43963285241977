import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class BlobErrorInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((err: unknown) => {
        if (
          err instanceof HttpErrorResponse &&
          err.error instanceof Blob &&
          err.error.type === 'application/json'
        ) {
          return this.getErrorFromBlob(err);
        }
        return throwError(() => err);
      }),
    );
  }

  private getErrorFromBlob(
    err: HttpErrorResponse,
  ): Promise<HttpEvent<unknown>> {
    // https://github.com/angular/angular/issues/19888
    // When request of type Blob, the error is also in Blob instead of object of the json data
    return new Promise<HttpEvent<unknown>>((_resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: Event): void => {
        try {
          const errmsg = JSON.parse(
            (e.target as unknown as { result: string }).result,
          ) as unknown;
          reject(
            new HttpErrorResponse({
              error: errmsg,
              headers: err.headers,
              status: err.status,
              statusText: err.statusText,
              url: err.url,
            }),
          );
        } catch {
          reject(err);
        }
      };
      reader.onerror = (): void => {
        reject(err);
      };
      reader.readAsText(err.error as Blob);
    });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, finalize, iif, map, of, switchMap } from 'rxjs';
import { MonoValueFilter, PaginationRange } from 'tiime-components';

import { CallbackComponentBase, CompaniesService, UsersService } from '@core';

import { BillerConnectionService } from '../../../settings/billers/components/biller-connection-form-dialog/biller-connection.service';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-biller-callback',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillerCallbackComponent extends CallbackComponentBase {
  constructor(
    router: Router,
    protected store: Store,
    protected usersService: UsersService,
    private readonly route: ActivatedRoute,
    private readonly billerConnectionService: BillerConnectionService,
    private readonly companiesService: CompaniesService,
  ) {
    super(router, store, usersService);
  }

  initAuthentication(): void {
    this.billerConnectionService
      .handleBillerWebauthResponse(this.route.snapshot.queryParams)
      .pipe(
        finalize(() => this.redirect()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private redirect(): void {
    this.getCompanyId()
      .pipe(
        map(companyId => {
          if (!companyId) {
            throw Error('No company found for user');
          }

          void this.router.navigate([
            'companies',
            companyId,
            'settings',
            'billers',
          ]);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getCompanyId(): Observable<number> {
    return this.getCurrentUser().pipe(
      switchMap(user =>
        iif(
          () => !!user.activeCompanyId || !!user.activeCompany,
          of(user.activeCompanyId || user.activeCompany.id),
          this.getFirstCompanyId(),
        ),
      ),
    );
  }

  private getFirstCompanyId(): Observable<number> {
    return this.companiesService
      .getAll({
        range: new PaginationRange(0, 0),
        filters: [new MonoValueFilter('work_in_companies', 'true')],
      })
      .pipe(map(({ data }) => (data.length ? data[0].id : null)));
  }
}

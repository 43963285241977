import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TiimeAuthService } from '@manakincubber/tiime-auth';
import { TrackingModule, TrackingService } from '@manakincubber/tiime-tracking';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { from, Observable } from 'rxjs';
import { LoadingService } from 'tiime-components';

import {
  AccountsTrackingGroup,
  AmplitudeModule,
  BusinessTrackingGroup,
  ClientTrackingGroup,
  GedTrackingGroup,
  InternalTrackingGroup,
  InvoiceTrackingGroup,
} from '@core/amplitude';
import { LoadingInterceptor } from '@interceptors';
import { AppConfigService } from '@services';
import { BusinessUnitEffects, businessUnitReducer } from '@store/business-unit';
import { CategoriesEffects, categoriesReducer } from '@store/categories';
import { DocumentsEffects, documentsReducer } from '@store/documents';
import { reducer as userReducer, UserEffects } from '@store/user';
import {
  WalletAccountEffects,
  walletAccountReducer,
} from '@store/wallet-account';

import { CompaniesComponent } from '../companies/companies.component';
import { SharedModule } from '../shared/shared.module';
import { ApiModule } from './api.module';
import { AuthModule } from './auth/auth.module';
import { bootstrapTracking } from './bootstrap-tracking';
import { extModules } from './build-specifics';
import { GlobalErrorHandler } from './global-error-handler';
import { NotFoundComponent } from './not-found/not-found.component';
import { SentryService } from './sentry/sentry.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../../i18n/${lang}.json`));
  }
}

@NgModule({
  imports: [
    ApiModule,
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      defaultLanguage: 'fr',
    }),
    StoreModule.forRoot({
      user: userReducer,
      walletAccount: walletAccountReducer,
      categories: categoriesReducer,
      documents: documentsReducer,
      businessUnit: businessUnitReducer,
    }),
    EffectsModule.forRoot([
      UserEffects,
      WalletAccountEffects,
      CategoriesEffects,
      DocumentsEffects,
      BusinessUnitEffects,
    ]),
    AmplitudeModule,
    TrackingModule.forRoot([
      BusinessTrackingGroup,
      InternalTrackingGroup,
      InvoiceTrackingGroup,
      ClientTrackingGroup,
      AccountsTrackingGroup,
      GedTrackingGroup,
    ]),
    extModules,
  ],
  declarations: [UnauthorizedComponent, CompaniesComponent, NotFoundComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (
        appConfigService: AppConfigService,
        tiimeAuthService: TiimeAuthService,
        sentryService: SentryService,
      ): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() => {
          // Init auth0 token refresh
          tiimeAuthService.scheduleRenewal();
          // Init sentry
          void sentryService.init();
        }),
      deps: [AppConfigService, TiimeAuthService, SentryService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrapTracking,
      deps: [AppConfigService, TrackingService, TiimeAuthService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    DeviceDetectorService,
    LoadingService,
  ],
  exports: [BrowserModule],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    translate: TranslateService,
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }

    translate.use('fr');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { LegalInformations } from '@models';
import { LegalService } from '@services/legal.service';

@Pipe({
  name: 'legalValidationDescription',
})
export class LegalValidationDescriptionPipe implements PipeTransform {
  constructor(private readonly legalService: LegalService) {}

  transform(legalInformations: LegalInformations): string {
    return this.legalService.areTosOrGdprUnsigned(legalInformations)
      ? `Il ne vous reste plus qu'à accepter nos Conditions Générales d'Utilisation et notre Politique de données personnelles.`
      : 'Nos Conditions Générales d’Utilisation ainsi que notre Politique de données personnelles ont été mises à jour.';
  }
}

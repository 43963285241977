import { NgModule } from '@angular/core';
import { mapToCanMatch, RouterModule, Routes } from '@angular/router';

import {
  AuthCallbackComponent,
  ChangePasswordComponent,
  CreatePasswordComponent,
  InvitationCallbackComponent,
  NotFoundComponent,
  SigninComponent,
  UnauthorizedComponent,
} from '@core';
import { BillerCallbackComponent } from '@core/auth/biller-callback/biller-callback.component';
import { ConnectAsCallbackComponent } from '@core/auth/connect-as-callback/connect-as-callback.component';
import { LegalValidationComponent } from '@core/auth/legal-validation/legal-validation.component';
import {
  AuthGuard,
  CompanyGuard,
  HasUserNotSignedTosAndGdprGuard,
  HasUserSignedTosAndGdprGuard,
  TiimeBusinessNotOnboardedGuard,
  UserGuard,
  passwordCreatedGuard,
  passwordNotCreatedGuard,
} from '@guards';

import { CompaniesComponent } from './companies/companies.component';

const routes: Routes = [
  { path: '', redirectTo: 'companies', pathMatch: 'full' },
  {
    path: 'companies',
    canMatch: [
      ...mapToCanMatch([AuthGuard]),
      passwordCreatedGuard,
      ...mapToCanMatch([HasUserSignedTosAndGdprGuard, CompanyGuard]),
    ],
    children: [
      {
        path: '',
        component: CompaniesComponent,
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./company/company.module').then(m => m.CompanyModule),
      },
      {
        path: ':id/settings',
        loadChildren: () =>
          import('./settings/settings.module').then(m => m.SettingsModule),
      },
    ],
  },
  {
    path: 'tiime-business-onboarding',
    loadChildren: () =>
      import(
        './tiime-business-onboarding/tiime-business-onboarding.module'
      ).then(m => m.TiimeBusinessOnboardingModule),
    canMatch: mapToCanMatch([
      AuthGuard,
      UserGuard,
      TiimeBusinessNotOnboardedGuard,
    ]),
  },
  { path: 'signin', component: SigninComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
  {
    path: 'go-cardless-callback',
    loadComponent: () =>
      import(
        './core/auth/go-cardless-callback/go-cardless-callback.component'
      ).then(r => r.GoCardlessCallbackComponent),
    canMatch: mapToCanMatch([AuthGuard, UserGuard]),
  },
  { path: 'connect-as', component: ConnectAsCallbackComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'invitation-callback', component: InvitationCallbackComponent },
  {
    path: 'createpassword',
    component: CreatePasswordComponent,
    canMatch: [
      ...mapToCanMatch([AuthGuard, UserGuard]),
      passwordNotCreatedGuard,
    ],
  },
  {
    path: 'renewpassword',
    component: CreatePasswordComponent,
    canMatch: [
      ...mapToCanMatch([AuthGuard, UserGuard]),
      passwordNotCreatedGuard,
    ],
    data: { renewPassword: true },
  },
  {
    path: 'legal-validation',
    component: LegalValidationComponent,
    canMatch: mapToCanMatch([
      AuthGuard,
      UserGuard,
      HasUserNotSignedTosAndGdprGuard,
    ]),
  },
  { path: 'biller-callback', component: BillerCallbackComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

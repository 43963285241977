import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { MonoValueFilter, PaginationRange } from 'tiime-components';

import { CompaniesService, WindowService } from '@core';
import { LOCAL_STORAGE } from '@core/tokens';
import { Company, User } from '@models';
import { AppConfigService } from '@services/app-config.service';
import { selectedCompanySelector, userSelector } from '@user-store';

@UntilDestroy()
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
})
export class CompaniesComponent implements OnInit {
  private readonly requestedCompanyParamName = 'company_id';

  constructor(
    private readonly companiesService: CompaniesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<Company>,
    private readonly appConfigService: AppConfigService,
    private readonly windowService: WindowService,
    @Inject(LOCAL_STORAGE) private readonly storage: Storage,
  ) {}

  ngOnInit(): void {
    this.redirectToCompany();
  }

  private redirectToCompany(): void {
    this.route.queryParamMap
      .pipe(
        take(1),
        switchMap((paramMap: ParamMap) => {
          const requestedCompanyId = paramMap.get(
            this.requestedCompanyParamName,
          );
          return requestedCompanyId
            ? of(requestedCompanyId)
            : this.getSelectedCompanyId();
        }),
        tap(companyId => {
          if (!companyId) {
            throw Error('No company found for user');
          }

          void this.router.navigate(['companies', companyId]);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private getSelectedCompanyId(): Observable<number> {
    return this.store.pipe(
      select(selectedCompanySelector),
      take(1),
      switchMap((company: Company) =>
        company ? of(company.id) : this.getSelectedCompanyIdFromApi(),
      ),
    );
  }

  private getSelectedCompanyIdFromApi(): Observable<number> {
    return this.store.pipe(
      select(userSelector),
      take(1),
      switchMap((user: User) => {
        if (user) {
          return of(user.activeCompanyId);
        } else {
          return this.companiesService
            .getAll({
              range: new PaginationRange(0, 0),
              filters: [new MonoValueFilter('work_in_companies', 'true')],
            })
            .pipe(map(companies => companies.data[0].id));
        }
      }),
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { LegalInformations, User } from '@models';
import { LegalService } from '@services/legal.service';

@Pipe({
  name: 'legalValidationTitle',
})
export class LegalValidationTitlePipe implements PipeTransform {
  constructor(private readonly legalService: LegalService) {}

  transform(legalInformations: LegalInformations, user: User): string {
    return this.legalService.areTosOrGdprUnsigned(legalInformations)
      ? `Félicitations ${user.firstName} !`
      : 'Mise à jour de nos documents légaux';
  }
}

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { map, filter, catchError, tap } from 'rxjs/operators';

import { Company } from '@models/company';
import { AppConfigService } from '@services/app-config.service';
import { selectedCompanySelector } from '@store/user/user-selector';

import packageInfo from '../../../../../../package.json';
import { APP_CODE, APP_PLATFORM } from '../constants/app.constants';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private companyId: number;

  constructor(
    private store: Store<any>,
    private router: Router,
    private readonly appConfigService: AppConfigService,
  ) {
    this.observeSelectedCompany();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('api/')) {
      return next.handle(request);
    }
    let url = this.replaceApi(request.url);
    if (url.includes('{companyId}')) {
      url = this.replaceCompanyId(url);
    }

    const sendableRequest: HttpRequest<any> = request.clone({
      url,
      setHeaders: {
        'tiime-app': APP_CODE,
        'tiime-app-platform': APP_PLATFORM,
        'tiime-app-version': packageInfo.version,
      },
    });

    return next.handle(sendableRequest).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === HttpStatusCode.Unauthorized
        ) {
          void this.router.navigate(['signin']);
        }
        return throwError(error);
      }),
    );
  }

  private observeSelectedCompany(): void {
    this.store
      .pipe(
        select(selectedCompanySelector),
        filter((company: Company) => !!company),
        map((company: Company) => company.id),
        tap((companyId: number) => (this.companyId = companyId)),
      )
      .subscribe();
  }

  private replaceApi(url: string): string {
    return `${this.appConfigService.appConfig.API_URL}/${url.replace(
      'api/',
      '',
    )}`;
  }

  private replaceCompanyId(url: string): string {
    return url.replace('{companyId}', String(this.companyId));
  }
}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  JWT_OPTIONS,
  JwtModule,
  TiimeAuthModule,
  TiimeAuthService,
} from '@manakincubber/tiime-auth';

import { AppConfigService } from '@core/services/app-config.service';
import { TiimeLayoutComponent } from '@shared';

import { SharedModule } from '../../shared/shared.module';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConnectAsCallbackComponent } from './connect-as-callback/connect-as-callback.component';
import { InvitationCallbackComponent } from './invitation-callback/invitation-callback.component';
import { jwtOptions } from './jwt-options-factory';
import { LegalValidationModule } from './legal-validation/legal-validation.module';
import { SigninModule } from './signin/signin.module';
import { StrongCustomerAuthenticationDialogComponent } from './strong-customer-authentication/strong-customer-authentication-dialog.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    SigninModule,
    TiimeAuthModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptions.options,
      },
    }),
    LegalValidationModule,
    TiimeLayoutComponent,
  ],
  declarations: [
    AuthCallbackComponent,
    ChangePasswordComponent,
    InvitationCallbackComponent,
    StrongCustomerAuthenticationDialogComponent,
    ConnectAsCallbackComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (
        appConfigService: AppConfigService,
        tiimeAuthService: TiimeAuthService,
      ): (() => Promise<void>) =>
        appConfigService.appConfigFactory<void>(() => {
          jwtOptions.addToAllowedDomains(
            appConfigService.appConfig.AUTH0_WHITE_DOMAINS,
          );
          tiimeAuthService.setConfig(appConfigService.appConfig);
        }),
      deps: [AppConfigService, TiimeAuthService],
      multi: true,
    },
  ],
})
export class AuthModule {}

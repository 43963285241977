import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ApiService, BudgetInsightService } from '@core';
import { AppConfigService } from '@services/app-config.service';

@Injectable()
export class BudgetInsightInterceptor implements HttpInterceptor {
  constructor(
    private readonly apiService: ApiService,
    private readonly budgetInsightService: BudgetInsightService,
    private readonly appConfigService: AppConfigService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !request.url.startsWith(BudgetInsightService.BUDGET_INSIGHT_DOMAIN_PREFIX)
    ) {
      return next.handle(request);
    }

    const token = this.budgetInsightService.getUserToken();
    const url = this.replaceBudgeaDomain(request.url);

    let sendableRequest: HttpRequest<any> = request.clone({
      url,
    });
    if (token) {
      sendableRequest = sendableRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(sendableRequest).pipe(
      catchError(error => {
        if (error.status === HttpStatusCode.Unauthorized) {
          return this.apiService.getBudgeaToken().pipe(
            tap((refreshedToken: string) =>
              this.budgetInsightService.setUserToken(refreshedToken),
            ),
            map((refreshedToken: string) =>
              sendableRequest.clone({
                setHeaders: {
                  Authorization: `Bearer ${refreshedToken}`,
                },
              }),
            ),
            switchMap((requestToRetry: HttpRequest<any>) =>
              next.handle(requestToRetry),
            ),
          );
        }
        return throwError(error);
      }),
    );
  }

  private replaceBudgeaDomain(url: string): string {
    return `${this.appConfigService.appConfig.BI_API_BASE}/${url.replace(
      `${BudgetInsightService.BUDGET_INSIGHT_DOMAIN_PREFIX}/`,
      '',
    )}`;
  }
}

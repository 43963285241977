import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { LegalDocumentType } from '@enums';
import {
  LegalInformations,
  LegalInformationsStatus as LIS,
  NotApplicableLegalInformationsStatus,
} from '@models';
import { LegalService } from '@services/legal.service';
import { userSelector } from '@user-store';

import { LegalValidationForm } from './legal-validation-form';

@UntilDestroy()
@Component({
  selector: 'app-legal-validation',
  templateUrl: './legal-validation.component.html',
  styleUrls: ['../auth-base.scss', './legal-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalValidationComponent {
  LEGAL_DOCUMENT = LegalDocumentType;
  isWalletActive = false;
  legalInformations?: LegalInformations;
  legalValidationForm = new LegalValidationForm();
  walletUser?: boolean;

  currentUser$ = this.store.pipe(select(userSelector), take(1));

  constructor(
    private readonly store: Store,
    private readonly legalService: LegalService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.legalService
      .getLegalInformations()
      .pipe(
        tap(legalInformations => {
          this.initFormValues(legalInformations);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * This function will init the form values after the values have been fetched from the api.
   * This is called at component initialisation and after submitting the form, in case something went wrong.
   * @param legalInformations values fetched from the api
   */
  private initFormValues(legalInformations: LegalInformations): void {
    this.legalInformations = legalInformations;
    this.isWalletActive =
      legalInformations.walletGdprStatus !==
        NotApplicableLegalInformationsStatus.NOT_APPLICABLE &&
      legalInformations.walletTermsOfServiceStatus !==
        NotApplicableLegalInformationsStatus.NOT_APPLICABLE;

    this.legalValidationForm.tos.setValue(
      this.isWalletActive
        ? legalInformations.walletTermsOfServiceStatus === LIS.UP_TO_DATE
        : legalInformations.termsOfServiceStatus === LIS.UP_TO_DATE,
    );

    this.legalValidationForm.gdpr.setValue(
      this.isWalletActive
        ? legalInformations.walletGdprStatus === LIS.UP_TO_DATE
        : legalInformations.gdprStatus === LIS.UP_TO_DATE,
    );
  }

  /**
   * Opens the desired doc (gdpr or terms of service) in a new tab
   * @param event To prevent the checkbox being toggled when the user clicks the link
   * @param doc Which document the user clicked on
   */
  openLegalDocument(event: Event, doc: LegalDocumentType): void {
    event.preventDefault();
    this.legalService
      .openLegalDocumentPdf(doc)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  /**
   * Creates an array with all the validation calls that must be done to the api,
   * makes all the calls then redirects to the page the user tried to access (or home).
   * @returns void
   */
  submitLegalValidationForm(): void {
    if (!this.legalValidationForm.valid) {
      this.legalValidationForm.markAllAsTouched();
      return;
    }
    const legalValidationsCalls: Observable<null>[] = [];

    if (this.legalInformations.termsOfServiceStatus !== LIS.UP_TO_DATE) {
      legalValidationsCalls.push(this.legalService.validateTos());
    }

    if (this.legalInformations.gdprStatus !== LIS.UP_TO_DATE) {
      legalValidationsCalls.push(this.legalService.validateGdpr());
    }

    if (this.isWalletActive) {
      if (
        this.legalInformations.walletTermsOfServiceStatus !== LIS.UP_TO_DATE
      ) {
        legalValidationsCalls.push(this.legalService.validateWalletTos());
      }

      if (this.legalInformations.walletGdprStatus !== LIS.UP_TO_DATE) {
        legalValidationsCalls.push(this.legalService.validateWalletGdpr());
      }
    }

    if (legalValidationsCalls.length) {
      zip(...legalValidationsCalls)
        .pipe(
          switchMap(() => {
            // Here we need to clear old value and fetch new one, otherwise the routes won't be accessible
            this.legalService.clearLegalInformations();
            return this.legalService.getLegalInformations();
          }),
          switchMap(legalInformations => {
            this.initFormValues(legalInformations);
            return this.router.navigate([
              this.activatedRoute.snapshot.queryParams['redirect'] || '',
            ]);
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }
}

import { TiimeAuthService } from '@manakincubber/tiime-auth';
import { TrackingService } from '@manakincubber/tiime-tracking';
import { Auth0UserProfile } from 'auth0-js';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { IMPERSONATE_EMAIL_REGEX } from '@constants';
import { AppOpenIdentified } from '@core/amplitude';
import { RuntimeEnvironment } from '@environments';
import { AppConfigService } from '@services';

export function bootstrapTracking(
  appConfigService: AppConfigService,
  trackingService: TrackingService,
  authService: TiimeAuthService,
): () => Observable<unknown> {
  return () => {
    const appConfig$ = appConfigService.appConfig
      ? of(appConfigService.appConfig)
      : appConfigService.isConfigReady$.pipe(
          map(() => appConfigService.appConfig),
        );

    const userInfo$ = authService.isAuthenticated()
      ? authService.userInfo()
      : of(null);

    return combineLatest([appConfig$, userInfo$]).pipe(
      take(1),
      tap(
        ([appConfig, userInfo]: [
          RuntimeEnvironment,
          Auth0UserProfile | null,
        ]) => {
          const isImpersonatedUser = userInfo
            ? IMPERSONATE_EMAIL_REGEX.test(userInfo.email)
            : false;

          if (appConfig.TRACKING.ENABLED && !isImpersonatedUser) {
            trackingService.startTracking();
          }

          if (authService.isAuthenticated()) {
            trackingService.dispatch(new AppOpenIdentified());
          }
        },
      ),
    );
  };
}
